import { render, staticRenderFns } from "./DocumentExtraction.vue?vue&type=template&id=3f00c9cf&scoped=true&"
import script from "./DocumentExtraction.vue?vue&type=script&lang=js&"
export * from "./DocumentExtraction.vue?vue&type=script&lang=js&"
import style0 from "./DocumentExtraction.vue?vue&type=style&index=0&id=3f00c9cf&lang=scss&scoped=true&"
import style1 from "./DocumentExtraction.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f00c9cf",
  null
  
)

export default component.exports