<template>
  <div class="home">
    <x-header>文档提取</x-header>
    <x-content v-if="loading" style="height:100%;" :contentStyle="{alignItems:'center',justifyContent:'center',height:'100%'}">
      <p>提取中，请稍等</p>
      <bounce-loading></bounce-loading>
    </x-content>
    <x-content v-else
      :contentStyle="{
        display: 'block',
        width: 'calc(100% - 32px)',
        padding: '16px',
      }"
    >
      <x-card>
        <el-row :gutter="20" class="pdfOcr">
          <el-col :span="12" :class="privewUrl?'leftData':'leftData centerData'">
            <div class="previewPdf" v-if="privewUrl">
              <iframe width="100%" height="100%" frameborder="0" :src="`/static/pdf/web/pdfviewer.html?file=${encodeURIComponent(privewUrl)}`+'#page=1'"></iframe>
            </div>
            <div class="uploadDiv">
              <div class="uploadText" v-if="!privewUrl">
                <el-row :gutter="35" >
                  <el-col :span="4">
                    <img  src="/static/img/latexOcr_img.png" class="icon">
                  </el-col>
                  <el-col :span="20" class="textData">
                  <h3>文档提取
                    <span class="tags">
                      <el-tag size="small">精准识别</el-tag>
                      <el-tag size="small">内容转换</el-tag>
                      <el-tag size="small">公式转换</el-tag>
                      </span>
                  </h3>
                  <p class="desc">
                      精准识别PDF中的图像、公式、表格及文本等多元内容，并将其高效转换为Markdown格式或JSON格式；对于数学公式，能够准确识别并转换为 LaTeX 格式，确保公式的专业呈现与精准表达。                    </p>
                  </el-col>  
                </el-row>
              </div>
              <el-upload
                accept=".pdf"
                class="upload-demo"
                :before-upload="beforeUpload"
                action=""
                :auto-upload="false"
                :file-list="fileList"
                :on-change="changeFile"
                drag
              >
              <img src="/static/img/pdf_icon.png" class="uploadPic">
                <div class="el-upload__text">
                  将需要识别的PDF文件拖到此处，或 <em>点击上传</em>
                  <div class="el-upload__tip" slot="tip">
                    支持最大50Mb的PDF文件
                  </div>
                </div>
              </el-upload>
  
            </div>
          </el-col>
          <el-col :span="12" v-if="privewUrl">
            <div class="rightData">
              <h4 class="h4">解析结果
                <el-button style="float: right;"
                        size="mini"
                        @click="copyJsonResult()"
                        round
                        type="success"
                        plain
                        icon="el-icon-document-copy"
                        >&nbsp;复制JSON</el-button
                      >
                <el-button style="float: right;margin-right: 10px;"
                        size="mini"
                        @click="copyMarkDownResult()"
                        round
                        type="success"
                        plain
                        icon="el-icon-document-copy"
                        >&nbsp;复制MarkDown</el-button
                      >
               
              </h4>
              <template v-if="markdownStr">

              <el-tabs >
                <el-tab-pane label="解析内容预览">
                  <div v-html="compiledMarkdown"></div>
                </el-tab-pane>
                <el-tab-pane label="MarkDown代码">
                  <p class="code">{{ markdownStr }}</p>
                </el-tab-pane>
                <el-tab-pane label="JSON格式">
                  <json-view v-if="jsonContent" :data="jsonContent" :deep="deep" icon-style="triangle" />

                </el-tab-pane>
            </el-tabs>
              </template>
              <el-empty v-else description="暂无解析结果"></el-empty>
            </div>
          </el-col>
        </el-row>
      </x-card>
    </x-content>
  </div>
</template>
<script>
import XCard from "../../components/busiess/XCard.vue";
import { DocumentExtraction } from "../../service/researchTools";
import { copyText } from '@/util/copyText';
import { marked } from "marked";
import MathFormula from '@/components/base/MathFormula.vue';
import jsonView from 'vue-json-views'

import katex from 'katex';
import 'katex/dist/katex.min.css';
// 自定义渲染器
const renderer = new marked.Renderer();
// 重写段落渲染方法，支持数学公式
renderer.paragraph = (text) => {
    // 处理块级公式：$$...$$
    const blockRegex = /\$\$([\s\S]+?)\$\$/g;
  text = text.replace(blockRegex, (match, p1) => {
    try {
      return katex.renderToString(p1, { displayMode: true });
    } catch (error) {
      console.error('KaTeX error in block formula:', error);
      return match; // 如果公式解析失败，返回原始内容
    }
  });

  // 再处理行内公式：$...$
  const inlineRegex = /\$([^$]+)\$/g;
  text = text.replace(inlineRegex, (match, p1) => {
    try {
      return katex.renderToString(p1, { displayMode: false });
    } catch (error) {
      console.error('KaTeX error in inline formula:', error);
      return match; // 如果公式解析失败，返回原始内容
    }
  });

  return `<p>${text}</p>`;
};

// 配置 marked
marked.setOptions({
  renderer,
  sanitize: false, // 禁用自动转义
});

export default {
  components: { XCard,MathFormula,jsonView },
  name: "ApplyUseManager",
  data() {
    return {
      loading:false,
      fileList: [],
      privewUrl:"",
      markdownStr:'',
      markdownData:'',
      jsonContent:{},
      deep:2
    };
  },
  filters: {},
  created() {},
  computed: {
    compiledMarkdown() {
      return marked(this.markdownStr);
    },
  },
  mounted() {     
},
  methods: {
    beforeUpload(file) {
      let acceptList = ["pdf"];
      const isLt50M = file.size / 1024 / 1024 < 50;
      let fileType = file.name.split(".").pop().toLowerCase();
      if (!isLt50M||acceptList.indexOf(fileType) === -1) {
        this.$message.error("仅支持上传不超过50M的PDF文件");
        return false;
      }
      return true;
    },
    // 文件状态改变时的钩子
    changeFile(file, fileList) {
      this.fileList = [];
      this.srcList = [];
      //this.renderMarkdown();
      this.loading=true
     fileList.forEach((item) => {
      this.$message.info("PDF文档内容提取中，请稍等");
      DocumentExtraction({ file: file.raw })
          .then((e) => {
            let data = this.APIParse(e);
            if (data) {
              this.$message.success("操作成功");
            // data.mdContent=`# Hello World!\n\nThis is a sample markdown with LaTeX:\n\n\n$$\\begin{aligned}&\\sin( \\alpha)^{ \\theta}= \\sum_{i=0}^{n} \\left( x^{i}+ \\cos(f) \\right)\\end{aligned}$$\n`
              this.privewUrl=data.url;
              this.markdownStr=data.mdContent
              this.renderMarkdown();
               // this.markdownData=this.htmlEncode(this.markdownData)
              this.$nextTick(() => {
                this.jsonContent=JSON.parse(data.jsonContent).pdf_info;




              });
            }else{
              this.$message.warning("暂无提取结果");
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
            this.markdownStr = "";
            this.privewUrl = "";
          })
          .finally((e) => {
            this.loading=false

          });
      });
    },
    renderMarkdown() {
     this.markdownData = marked(this.markdownStr);
    },
    copyMarkDownResult() {
      if (!this.markdownStr) {
        this.$message.warning("暂无数据，无法复制");
      } else {
        copyText(this.markdownStr, this)
      }
    },
    copyJsonResult() {
      if (!this.jsonContent) {
        this.$message.warning("暂无数据，无法复制");
      } else {
        copyText(JSON.stringify(this.jsonContent), this)
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  overflow: auto;
}

</style>
<style >
.pdfOcr {
  padding: 20px;
}
.pdfOcr .base-col-12 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  max-width: calc(50% - 50px);
  margin: 0px 25px;
}
.pdfOcr .centerData{
  width: 800px;
    max-width: 100%;
    margin: 0 auto;
    display: block;
    float: none;
    box-shadow: none;
}
@media (min-width: 1920px) {
  .pdfOcr .centerData{
    margin: 10vh auto;
  }

}
.pdfOcr .uploadDiv {
  min-height: 188px;

  padding: 20px;
  position: relative;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 20px;
  /** 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: #f0f3f7;
    border: 1px solid #c3d0e1;
    */
}
.pdfOcr .uploadText .icon{
  width: 100%;

}
.pdfOcr .uploadText .textData{
  text-align: left;
  margin-bottom: 15px;
}
.pdfOcr .uploadText h3{
  font-size: 20px;
    color: #1441c2;
    font-weight: bold;
    margin-bottom: 10px;
}
.pdfOcr .uploadText .tags{
  margin-left: 20px;
}
.pdfOcr .uploadText .tags .base-tag{
  margin-right: 5px;

}
.pdfOcr .uploadText .desc{
line-height: 28px;
}
.pdfOcr .base-col-offset-6 {
  margin-left: 25% !important;
}
.pdfOcr .uploadDiv .base-upload {
  width: 100%;
  display: block;
}

.pdfOcr .uploadDiv .el-upload-dragger {
  width: 100% !important;
}

.pdfOcr .uploadDiv .base-upload {
  display: block;
}

.pdfOcr .uploadDiv .base-upload-dragger {
  width: 100% !important;
  height: 200px;

  background-image: linear-gradient(to top, #5c93ff14,#fff);

}
.pdfOcr .centerData .uploadDiv .base-upload-dragger {
  height: 220px;

}
.pdfOcr .uploadDiv .base-upload-dragger:hover {
  background-image: linear-gradient(#9cecfb,#e8f3ff,#ffffff);

}
.pdfOcr .uploadDiv .el-upload__tip {
  margin-top: 10px;

}
.pdfOcr .base-upload-dragger .el-icon-upload,.pdfOcr .base-upload-dragger .iconfont {
  font-size: 80px;
  color: #c0c4cc;
  margin: 40px 0 16px;
  line-height: 50px;
  vertical-align: baseline;
    display: inline-block;
}
.pdfOcr .base-upload-dragger .uploadPic{
  padding-top: 20px;
  width: 80px;
}
.pdfOcr .base-upload-dragger .el-upload__text {
  color: #606266;
  font-size: 14px;
  text-align: center;
  margin-top: 20px;

}

.pdfOcr .base-upload-dragger .el-upload__text em {
  color: #409eff;
  font-style: normal;
}
.pdfOcr .previewPdf {
  margin: 20px;
  margin-bottom: 10px;
  height: calc(100vh - 238px);
}
.pdfOcr .previewPdf .img {
  display: block;
  margin: 0 auto;

  width: auto;
  max-width: 100%;
}
.pdfOcr .previewPdf .img img {
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  padding: 20px;
}
.pdfOcr .previewPdf .base-image__preview {
  width: auto;
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
.pdfOcr .rightData {
  margin: 20px;
  height: 100vh;
  overflow: auto;
  padding: 0px 10px;
}
.pdfOcr .rightData .h4 {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-weight: bold;
}
.pdfOcr .rightData .base-tabs__item.is-active{
 color: #409eff;
}
.pdfOcr .rightData .base-tabs__active-bar{
  background-color: #409eff;
}
.pdfOcr .rightData .code,.pdfOcr .rightData p{
  line-height: 30px;
  word-break: break-all;
}
.pdfOcr .rightData table {
  border-collapse: collapse;
  margin-bottom: 10px;
}
.pdfOcr .rightData table, th, td {
  border: 1px solid #bfc3de;
  padding: 2px 5px;
}
.pdfOcr .rightData table td{
  line-height: 30px;
}
.pdfOcr .rightData::-webkit-scrollbar{
		width: 4px;
	}
  .pdfOcr .rightData::-webkit-scrollbar-thumb{
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		opacity: 0.2;
		background: fade(#8398de, 60%);
	}
  /**.pdfOcr .rightData::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 0;
		background: fade(#8398de, 30%);
	} */
</style>