import http from '../util/http.js'

export const LatexOcr = (params) => http.upload('/researchTools/latexOcr',params)
export const ChemistryOcr = (params) => http.upload('/researchTools/chemistryOcr',params)
export const DocumentExtraction = (params) => http.upload('/researchTools/documentExtraction',params)


//export const PowerMindMap = (params) => http.post('/researchTools/powerMindMap',params)
export const PowerMindMap = (params) => http.postPowerJSON('/power',params)




