<template>
  <div ref="formula" class="math-formula">
    <div v-html="value"></div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
  },
  watch: {
    value() {
      this.$nextTick(() => {
        this.typesetMath();
      });
    }
  },
  mounted() {
    // 如果 MathJax 已经存在，则立即排版
    if (window.MathJax) {
      this.typesetMath();
    } else {
      // 设置 MathJax 配置
      window.MathJax = {
        tex: {
          inlineMath: [['$', '$'], ['\$', '\$']],
          displayMath: [['$$', '$$'], ['\$', '\$']]
        },
        //loader: { load: ['input/tex', 'output/chtml'] },
        startup: {
          ready: () => {
            MathJax.startup.defaultReady();
            // 确保 MathJax 加载完毕后调用 typesetMath 方法
            this.typesetMath();
          }
        },
        chtml: {
          fontCache: 'none'
        }
      };

      // 动态加载 MathJax 脚本
      const script = document.createElement('script');
      script.src = '/static/js/tex-chtml.js';

      //script.src = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml.js'; // 使用 CDN 提供的最新稳定版本
      script.async = true;
      script.onload = () => {
        // 等待 MathJax 完成加载并初始化后再调用 typesetMath
        window.MathJax.startup.promise.then(() => this.typesetMath());
      };
      document.head.appendChild(script);
    }
  },
  updated() {
    this.typesetMath();
  },
  methods: {
    typesetMath() {
      if (window.MathJax) {
        window.MathJax.typesetPromise([this.$refs.formula])
          .catch(err => console.error('Typesetting failed:', err));
      }
    }
  }
};
</script>
  <style scoped>
  .math-formula {
    /* 根据需要添加样式 */
  /**text-align: center;*/
    overflow-x: auto;
    min-height: 72px;
    height: auto;
    margin-bottom: 20px;

  }
.math-formula::-webkit-scrollbar {
		width: 4px;
	}
.math-formula::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		opacity: 0.2;
		background: fade(#8398de, 60%);
	}
.math-formula::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 0;
		background: fade(#8398de, 30%);
	}

  </style>
  <style>
.math-formula mjx-container[jax="CHTML"][display="true"]{
    display: initial !important;
  }
</style>